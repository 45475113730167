import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='has-text-centered'>\n  <LinkTo @route='authenticated.index'>\n    <img\n      ...attributes\n      data-test-img-logo\n      src={{this.whitelabel.logo}}\n      alt={{this.whitelabel.name}}\n    />\n  </LinkTo>\n</div>", {"contents":"<div class='has-text-centered'>\n  <LinkTo @route='authenticated.index'>\n    <img\n      ...attributes\n      data-test-img-logo\n      src={{this.whitelabel.logo}}\n      alt={{this.whitelabel.name}}\n    />\n  </LinkTo>\n</div>","moduleName":"irene/components/img-logo/index.hbs","parseOptions":{"srcName":"irene/components/img-logo/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import WhitelabelService from 'irene/services/whitelabel';

export default class ImgLogoComponent extends Component {
  @service declare whitelabel: WhitelabelService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ImgLogo: typeof ImgLogoComponent;
  }
}
