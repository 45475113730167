import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack data-test-capturedApi-endpointContainer @spacing='1' @tag='label'>\n  <AkCheckbox\n    @checked={{@capturedApi.isActive}}\n    @onChange={{perform this.handleToggleApi}}\n    @disabled={{this.handleToggleApi.isRunning}}\n    data-test-capturedApi-endpointSelectCheckbox\n  />\n\n  <AkStack local-class='api-endpoint-container' @spacing='1.5'>\n    <AkChip\n      data-test-capturedApi-endpointMethodChip\n      @label={{@capturedApi.request.method}}\n      @size='small'\n    />\n\n    <AkTypography local-class='api-endpoint' data-test-capturedApi-endpointUrl>\n      {{request-to-url @capturedApi.request}}\n\n      {{#if this.handleToggleApi.isRunning}}\n        <AkLoader class='mx-1' @size={{13}} />\n      {{/if}}\n    </AkTypography>\n  </AkStack>\n</AkStack>", {"contents":"<AkStack data-test-capturedApi-endpointContainer @spacing='1' @tag='label'>\n  <AkCheckbox\n    @checked={{@capturedApi.isActive}}\n    @onChange={{perform this.handleToggleApi}}\n    @disabled={{this.handleToggleApi.isRunning}}\n    data-test-capturedApi-endpointSelectCheckbox\n  />\n\n  <AkStack local-class='api-endpoint-container' @spacing='1.5'>\n    <AkChip\n      data-test-capturedApi-endpointMethodChip\n      @label={{@capturedApi.request.method}}\n      @size='small'\n    />\n\n    <AkTypography local-class='api-endpoint' data-test-capturedApi-endpointUrl>\n      {{request-to-url @capturedApi.request}}\n\n      {{#if this.handleToggleApi.isRunning}}\n        <AkLoader class='mx-1' @size={{13}} />\n      {{/if}}\n    </AkTypography>\n  </AkStack>\n</AkStack>","moduleName":"irene/components/file-details/scan-actions-old/api-scan/captured-apis/overview/index.hbs","parseOptions":{"srcName":"irene/components/file-details/scan-actions-old/api-scan/captured-apis/overview/index.hbs"}});
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import CapturedApiModel from 'irene/models/capturedapi';

export interface FileDetailsScanActionsOldApiScanCapturedApiOverviewSignature {
  Args: {
    capturedApi: CapturedApiModel;
    toggleApi: () => Promise<void>;
  };
}

export default class FileDetailsScanActionsOldApiScanCapturedApiOverviewComponent extends Component<FileDetailsScanActionsOldApiScanCapturedApiOverviewSignature> {
  handleToggleApi = task(async () => {
    await this.args.toggleApi();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::ScanActionsOld::ApiScan::CapturedApis::Overview': typeof FileDetailsScanActionsOldApiScanCapturedApiOverviewComponent;
  }
}
