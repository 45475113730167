export default {
  "form": "_form_1elb9o",
  "checkbox-field": "_checkbox-field_1elb9o",
  "input-wrap": "_input-wrap_1elb9o",
  "label-error": "_label-error_1elb9o",
  "error-msg": "_error-msg_1elb9o",
  "half-wrap": "_half-wrap_1elb9o",
  "input-field": "_input-field_1elb9o",
  "has-error": "_has-error_1elb9o"
};
