export default {
  "marvel-device": "_marvel-device_cek5jl",
  "screen": "_screen_cek5jl",
  "top-bar": "_top-bar_cek5jl",
  "bottom-bar": "_bottom-bar_cek5jl",
  "middle-bar": "_middle-bar_cek5jl",
  "iphone8": "_iphone8_cek5jl",
  "home": "_home_cek5jl",
  "sleep": "_sleep_cek5jl",
  "volume": "_volume_cek5jl",
  "camera": "_camera_cek5jl",
  "sensor": "_sensor_cek5jl",
  "speaker": "_speaker_cek5jl",
  "gold": "_gold_cek5jl",
  "black": "_black_cek5jl",
  "landscape": "_landscape_cek5jl",
  "iphone8plus": "_iphone8plus_cek5jl",
  "iphone5s": "_iphone5s_cek5jl",
  "iphone5c": "_iphone5c_cek5jl",
  "silver": "_silver_cek5jl",
  "red": "_red_cek5jl",
  "yellow": "_yellow_cek5jl",
  "green": "_green_cek5jl",
  "blue": "_blue_cek5jl",
  "iphone4s": "_iphone4s_cek5jl",
  "nexus5": "_nexus5_cek5jl",
  "s5": "_s5_cek5jl",
  "lumia920": "_lumia920_cek5jl",
  "white": "_white_cek5jl",
  "htc-one": "_htc-one_cek5jl",
  "ipad": "_ipad_cek5jl",
  "macbook": "_macbook_cek5jl",
  "iphone-x": "_iphone-x_cek5jl",
  "overflow": "_overflow_cek5jl",
  "shadow": "_shadow_cek5jl",
  "shadow--tl": "_shadow--tl_cek5jl",
  "shadow--tr": "_shadow--tr_cek5jl",
  "shadow--bl": "_shadow--bl_cek5jl",
  "shadow--br": "_shadow--br_cek5jl",
  "inner-shadow": "_inner-shadow_cek5jl",
  "notch": "_notch_cek5jl",
  "note8": "_note8_cek5jl",
  "sensors": "_sensors_cek5jl",
  "more-sensors": "_more-sensors_cek5jl",
  "inner": "_inner_cek5jl"
};
