import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @spacing='0.5' @alignItems='center'>\n\n  <AkStack @spacing='1' @alignItems='center'>\n    <AkIcon @iconName={{this.iconName}} @size='small' @color='primary' />\n\n    {{#if this.showLabel}}\n      <AkTypography local-class='trigger-label'>\n        {{this.optionTitle}}\n        -\n      </AkTypography>\n    {{/if}}\n  </AkStack>\n\n  <AkTypography @fontWeight='medium' local-class='trigger-label'>\n    {{this.selectedItem}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack @spacing='0.5' @alignItems='center'>\n\n  <AkStack @spacing='1' @alignItems='center'>\n    <AkIcon @iconName={{this.iconName}} @size='small' @color='primary' />\n\n    {{#if this.showLabel}}\n      <AkTypography local-class='trigger-label'>\n        {{this.optionTitle}}\n        -\n      </AkTypography>\n    {{/if}}\n  </AkStack>\n\n  <AkTypography @fontWeight='medium' local-class='trigger-label'>\n    {{this.selectedItem}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/app-monitoring/settings/filter-selected-item/index.hbs","parseOptions":{"srcName":"irene/components/app-monitoring/settings/filter-selected-item/index.hbs"}});
import Component from '@glimmer/component';

interface AppMonitoringSettingsFilterSelectedItemArgs {
  extra?: Record<string, unknown>;
}

export default class AppMonitoringSettingsFilterSelectedItemComponent extends Component<AppMonitoringSettingsFilterSelectedItemArgs> {
  get optionTitle() {
    return this.args.extra?.['optionTitle'];
  }

  get selectedItem() {
    return this.args.extra?.['selectedItem'];
  }

  get showLabel() {
    return this.args.extra?.['showLabel'];
  }

  get iconName() {
    return this.args.extra?.['iconName'];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AppMonitoring::Settinngs::FilterSelectedItem': typeof AppMonitoringSettingsFilterSelectedItemComponent;
  }
}
