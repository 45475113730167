import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.optionTitle}}\n  <AkTypography @variant='subtitle1' local-class='before-option-label'>\n    {{this.optionTitle}}\n  </AkTypography>\n{{/if}}\n\n{{#if @searchEnabled}}\n  <div local-class='before-option-search'>\n    <AkTextField\n      @placeholder={{@searchPlaceholder}}\n      @value={{@select.searchText}}\n      {{on 'input' this.handleInput}}\n      {{on 'focus' @onFocus}}\n      {{on 'blur' @onBlur}}\n      {{on 'keyup' this.handleKeydown}}\n      {{did-insert this.focusInput}}\n      {{will-destroy this.clearSearch}}\n      local-class='team-name-text-input'\n    >\n      <:leftAdornment>\n        <AkIcon @iconName='search' @color='textSecondary' />\n      </:leftAdornment>\n    </AkTextField>\n  </div>\n{{/if}}", {"contents":"{{#if this.optionTitle}}\n  <AkTypography @variant='subtitle1' local-class='before-option-label'>\n    {{this.optionTitle}}\n  </AkTypography>\n{{/if}}\n\n{{#if @searchEnabled}}\n  <div local-class='before-option-search'>\n    <AkTextField\n      @placeholder={{@searchPlaceholder}}\n      @value={{@select.searchText}}\n      {{on 'input' this.handleInput}}\n      {{on 'focus' @onFocus}}\n      {{on 'blur' @onBlur}}\n      {{on 'keyup' this.handleKeydown}}\n      {{did-insert this.focusInput}}\n      {{will-destroy this.clearSearch}}\n      local-class='team-name-text-input'\n    >\n      <:leftAdornment>\n        <AkIcon @iconName='search' @color='textSecondary' />\n      </:leftAdornment>\n    </AkTextField>\n  </div>\n{{/if}}","moduleName":"irene/components/ak-select/before-option/index.hbs","parseOptions":{"srcName":"irene/components/ak-select/before-option/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { Select } from 'ember-power-select/components/power-select';
import { later, scheduleOnce } from '@ember/runloop';

interface AkSelectBeforeOptionArgs {
  select: Select;
  searchPlaceholder?: string;
  searchEnabled?: boolean;
  extra?: Record<string, unknown>;
  autofocus?: boolean;
  onBlur: (e: FocusEvent) => void;
  onFocus: (e: FocusEvent) => void;
  onInput: (e: InputEvent) => boolean;
  onKeydown: (e: KeyboardEvent) => false | void;
}

export default class AkSelectBeforeOptionComponent extends Component<AkSelectBeforeOptionArgs> {
  get optionTitle() {
    return this.args.extra?.['optionTitle'];
  }

  @action
  clearSearch(): void {
    scheduleOnce('actions', this.args.select.actions, 'search', '');
  }

  @action
  focusInput(el: HTMLElement) {
    later(() => {
      if (this.args.autofocus !== false) {
        el.focus();
      }
    }, 0);
  }

  @action
  handleKeydown(e: KeyboardEvent): false | void {
    if (this.args.onKeydown(e) === false) {
      return false;
    }
    if (e.keyCode === 13) {
      this.args.select.actions.close(e);
    }
  }

  @action
  handleInput(event: Event): false | void {
    const e = event as InputEvent;
    if (this.args.onInput(e) === false) {
      return false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AkSelect::BeforeOption': typeof AkSelectBeforeOptionComponent;
  }
}
