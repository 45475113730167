export default {
  "header": "_header_3g4tja",
  "title": "_title_3g4tja",
  "table": "_table_3g4tja",
  "table-header": "_table-header_3g4tja",
  "table-row": "_table-row_3g4tja",
  "has-report-column": "_has-report-column_3g4tja",
  "has-results-column": "_has-results-column_3g4tja",
  "app": "_app_3g4tja",
  "app-icon": "_app-icon_3g4tja",
  "app-name": "_app-name_3g4tja",
  "fileid-key": "_fileid-key_3g4tja",
  "fileid-value": "_fileid-value_3g4tja",
  "version-info": "_version-info_3g4tja",
  "version": "_version_3g4tja",
  "version-key": "_version-key_3g4tja",
  "version-value": "_version-value_3g4tja",
  "uploaded": "_uploaded_3g4tja",
  "summary": "_summary_3g4tja",
  "pagination": "_pagination_3g4tja",
  "loading-error": "_loading-error_3g4tja",
  "loading": "_loading_3g4tja",
  "empty-data": "_empty-data_3g4tja"
};
