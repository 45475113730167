import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @width='full'\n  @spacing='2'\n  @flexWrap='wrap'\n  data-test-fileDetailScanActions-scan-type-cards\n>\n  <div local-class='scan-status-card'>\n    <FileDetails::ScanActions::StaticScan @file={{@file}} />\n  </div>\n\n  <div local-class='scan-status-card'>\n    <FileDetails::ScanActions::DynamicScan @file={{@file}} />\n  </div>\n\n  {{#if this.isAPIScanEnabled}}\n    <div local-class='scan-status-card'>\n      <FileDetails::ScanActions::ApiScan @file={{@file}} />\n    </div>\n  {{/if}}\n\n  {{#unless this.isManualScanDisabled}}\n    <div local-class='scan-status-card'>\n      <FileDetails::ScanActions::ManualScan @file={{@file}} />\n    </div>\n  {{/unless}}\n</AkStack>", {"contents":"<AkStack\n  @width='full'\n  @spacing='2'\n  @flexWrap='wrap'\n  data-test-fileDetailScanActions-scan-type-cards\n>\n  <div local-class='scan-status-card'>\n    <FileDetails::ScanActions::StaticScan @file={{@file}} />\n  </div>\n\n  <div local-class='scan-status-card'>\n    <FileDetails::ScanActions::DynamicScan @file={{@file}} />\n  </div>\n\n  {{#if this.isAPIScanEnabled}}\n    <div local-class='scan-status-card'>\n      <FileDetails::ScanActions::ApiScan @file={{@file}} />\n    </div>\n  {{/if}}\n\n  {{#unless this.isManualScanDisabled}}\n    <div local-class='scan-status-card'>\n      <FileDetails::ScanActions::ManualScan @file={{@file}} />\n    </div>\n  {{/unless}}\n</AkStack>","moduleName":"irene/components/file-details/scan-actions/index.hbs","parseOptions":{"srcName":"irene/components/file-details/scan-actions/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import FileModel from 'irene/models/file';
import OrganizationService from 'irene/services/organization';

export interface FileDetailsScanActionsSignature {
  Args: {
    file: FileModel;
  };
}

export default class FileDetailsScanActionsComponent extends Component<FileDetailsScanActionsSignature> {
  @service declare organization: OrganizationService;

  get isManualScanDisabled() {
    return !this.args.file.project.get('isManualScanAvailable');
  }

  get isAPIScanEnabled() {
    return this.args.file.project.get('isAPIScanEnabled');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::ScanActions': typeof FileDetailsScanActionsComponent;
  }
}
