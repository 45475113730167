import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Textarea\n  autocomplete='off'\n  autocorrect='off'\n  autocapitalize='off'\n  spellcheck='false'\n  rows='4'\n  cols='10'\n  class='textarea-input'\n  name='finding-description'\n  aria-label='finding description'\n  @value={{@finding.description}}\n  data-test-securityAnalysisDetails-findingDescription\n/>", {"contents":"<Textarea\n  autocomplete='off'\n  autocorrect='off'\n  autocapitalize='off'\n  spellcheck='false'\n  rows='4'\n  cols='10'\n  class='textarea-input'\n  name='finding-description'\n  aria-label='finding description'\n  @value={{@finding.description}}\n  data-test-securityAnalysisDetails-findingDescription\n/>","moduleName":"irene/components/security/analysis-details/findings/table/description/index.hbs","parseOptions":{"srcName":"irene/components/security/analysis-details/findings/table/description/index.hbs"}});
import Component from '@glimmer/component';
import type { SecurityAnalysisFinding } from 'irene/models/security/analysis';

export interface SecurityAnalysisDetailsFindingsTableDescriptionComponentSignature {
  Args: {
    finding: SecurityAnalysisFinding;
  };
}

export default class SecurityAnalysisDetailsFindingsTableDescriptionComponent extends Component<SecurityAnalysisDetailsFindingsTableDescriptionComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'security/analysis-details/findings/table/description': typeof SecurityAnalysisDetailsFindingsTableDescriptionComponent;
  }
}
