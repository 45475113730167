export default {
  "sso-settings-header-container": "_sso-settings-header-container_18e999",
  "sso-settings-main-container": "_sso-settings-main-container_18e999",
  "bordered-box": "_bordered-box_18e999",
  "bordered-box-section": "_bordered-box-section_18e999",
  "full-bordered-box-section": "_full-bordered-box-section_18e999",
  "full-bordered-box-section-column": "_full-bordered-box-section-column_18e999",
  "header-icon": "_header-icon_18e999",
  "dropzone-upload-active": "_dropzone-upload-active_18e999",
  "browse-file-btn": "_browse-file-btn_18e999"
};
