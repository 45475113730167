export default {
  "invitation": "_invitation_1lsv64",
  "invitation-expired": "_invitation-expired_1lsv64",
  "user": "_user_1lsv64",
  "email": "_email_1lsv64",
  "name": "_name_1lsv64",
  "company": "_company_1lsv64",
  "date": "_date_1lsv64",
  "expired": "_expired_1lsv64",
  "source": "_source_1lsv64",
  "source-backoffice": "_source-backoffice_1lsv64",
  "source-invitation": "_source-invitation_1lsv64",
  "source-registration": "_source-registration_1lsv64",
  "actions": "_actions_1lsv64"
};
