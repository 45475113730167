import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FileDetails::VulnerabilityAnalysisDetails::RegulatoryContent\n  @contents={{@contents}}\n>\n  <:value as |cwe|>\n    <AkButton\n      data-test-analysisDetails-regulatoryValue\n      @tag='a'\n      @variant='text'\n      @color='primary'\n      href='{{cwe.url}}'\n      target='_blank'\n      rel='noopener noreferrer'\n    >\n      {{cwe.url}}\n    </AkButton>\n  </:value>\n</FileDetails::VulnerabilityAnalysisDetails::RegulatoryContent>", {"contents":"<FileDetails::VulnerabilityAnalysisDetails::RegulatoryContent\n  @contents={{@contents}}\n>\n  <:value as |cwe|>\n    <AkButton\n      data-test-analysisDetails-regulatoryValue\n      @tag='a'\n      @variant='text'\n      @color='primary'\n      href='{{cwe.url}}'\n      target='_blank'\n      rel='noopener noreferrer'\n    >\n      {{cwe.url}}\n    </AkButton>\n  </:value>\n</FileDetails::VulnerabilityAnalysisDetails::RegulatoryContent>","moduleName":"irene/components/file-details/vulnerability-analysis-details/regulatory-content/cwe/index.hbs","parseOptions":{"srcName":"irene/components/file-details/vulnerability-analysis-details/regulatory-content/cwe/index.hbs"}});
import Component from '@glimmer/component';
import CweModel from 'irene/models/cwe';

export interface FileDetailsVulnerabilityAnalysisDetailsRegulatoryContentCweSignature {
  Args: {
    contents: CweModel[];
  };
}

export default class FileDetailsVulnerabilityAnalysisDetailsRegulatoryContentCweComponent extends Component<FileDetailsVulnerabilityAnalysisDetailsRegulatoryContentCweSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'file-details/vulnerability-analysis-details/regulatory-content/cwe': typeof FileDetailsVulnerabilityAnalysisDetailsRegulatoryContentCweComponent;
  }
}
