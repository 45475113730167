import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='px-2'>\n  <AkBreadcrumbs::Container\n    class='mt-2 mb-3'\n    data-test-fileDetails-breadcrumbContainer\n  >\n    {{#each this.breadcrumbItems as |item|}}\n      <AkBreadcrumbs::Item\n        @route={{item.route}}\n        @model={{item.model}}\n        @linkTitle={{item.linkTitle}}\n        data-test-fileDetails-breadcrumbItem='{{item.linkTitle}}'\n      />\n    {{/each}}\n  </AkBreadcrumbs::Container>\n\n  <AkStack @direction='column' @spacing='2.5'>\n    <FileDetails::SummaryOld @file={{@file}} />\n\n    <FileDetails::ScanActionsOld @file={{@file}} />\n\n    <FileDetails::InsightsWrapper @file={{@file}} />\n  </AkStack>\n\n  <FileDetails::VulnerabilityAnalysis @file={{@file}} />\n</div>", {"contents":"<div class='px-2'>\n  <AkBreadcrumbs::Container\n    class='mt-2 mb-3'\n    data-test-fileDetails-breadcrumbContainer\n  >\n    {{#each this.breadcrumbItems as |item|}}\n      <AkBreadcrumbs::Item\n        @route={{item.route}}\n        @model={{item.model}}\n        @linkTitle={{item.linkTitle}}\n        data-test-fileDetails-breadcrumbItem='{{item.linkTitle}}'\n      />\n    {{/each}}\n  </AkBreadcrumbs::Container>\n\n  <AkStack @direction='column' @spacing='2.5'>\n    <FileDetails::SummaryOld @file={{@file}} />\n\n    <FileDetails::ScanActionsOld @file={{@file}} />\n\n    <FileDetails::InsightsWrapper @file={{@file}} />\n  </AkStack>\n\n  <FileDetails::VulnerabilityAnalysis @file={{@file}} />\n</div>","moduleName":"irene/components/file-details/index.hbs","parseOptions":{"srcName":"irene/components/file-details/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import FileModel from 'irene/models/file';

export interface FileDetailsSignature {
  Args: {
    file: FileModel;
  };
}

export default class FileDetailsComponent extends Component<FileDetailsSignature> {
  @service declare intl: IntlService;

  get breadcrumbItems() {
    return [
      {
        route: 'authenticated.dashboard.projects',
        linkTitle: this.intl.t('allProjects'),
      },
      {
        route: 'authenticated.dashboard.file',
        linkTitle: this.intl.t('scanDetails'),
        model: this.args.file.id,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FileDetails: typeof FileDetailsComponent;
  }
}
