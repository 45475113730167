import Model, { attr, belongsTo } from '@ember-data/model';
import { action } from '@ember/object';

import SbomVulnerabilityModel from './sbom-vulnerability';

export interface VulnerabilityAuditVersionRange {
  version: string | null;
  less_than?: string | null;
  less_than_or_equal?: string | null;
}

export interface VulnerabilityAuditVersion {
  version: string | null;
  version_range: string;
}

type ParsedVersion = {
  version: string | null;
  version_range: VulnerabilityAuditVersionRange | null;
};

export default class SbomVulnerabilityAuditModel extends Model {
  @belongsTo('sbom-vulnerability', { async: false })
  declare sbVulnerability: SbomVulnerabilityModel;

  @attr()
  declare versions: VulnerabilityAuditVersion[];

  get parsedVersions() {
    const versionSet = new Set<string>();

    return this.versions
      .map((v) => {
        // to remove duplicates
        const stringifiedVersion = JSON.stringify(v);

        if (versionSet.has(stringifiedVersion)) {
          return null;
        }

        versionSet.add(stringifiedVersion);

        return {
          ...v,
          version_range: (() => {
            // incase json parse fails
            try {
              return JSON.parse(
                v.version_range
              ) as VulnerabilityAuditVersionRange;
            } catch (error) {
              return null;
            }
          })(),
        };
      })
      .filter(Boolean) as ParsedVersion[];
  }

  @action
  humanizedVersionRange(versionRange: VulnerabilityAuditVersionRange) {
    let affectedRange: string | null = null;
    let fixedRange: string | null = null;

    const { version, less_than, less_than_or_equal } = versionRange;

    if (this.isValueEmpty(version)) {
      if (!this.isValueEmpty(less_than_or_equal)) {
        affectedRange = `<= ${less_than_or_equal}`;
      } else if (!this.isValueEmpty(less_than)) {
        affectedRange = `< ${less_than}`;
      }
    } else {
      if (!this.isValueEmpty(less_than_or_equal)) {
        affectedRange = `>= ${version}, <= ${less_than_or_equal}`;
      } else if (!this.isValueEmpty(less_than)) {
        affectedRange = `>= ${version}, < ${less_than}`;
      } else {
        affectedRange = version;
      }
    }

    fixedRange = this.isValueEmpty(less_than) ? null : (less_than as string);

    return affectedRange === null && fixedRange === null
      ? null
      : { affectedRange, fixedRange };
  }

  isValueEmpty(value?: string | null) {
    return (
      value === null ||
      value === undefined ||
      !value.trim() ||
      value.trim() === '0'
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sbom-vulnerability-audit': SbomVulnerabilityAuditModel;
  }
}
