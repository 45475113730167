import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Textarea\n  @value={{@finding.title}}\n  autocomplete='off'\n  autocorrect='off'\n  autocapitalize='off'\n  spellcheck='false'\n  rows='4'\n  cols='10'\n  class='textarea-input'\n  aria-label='finding title'\n  data-test-securityAnalysisDetails-findingTitle\n/>", {"contents":"<Textarea\n  @value={{@finding.title}}\n  autocomplete='off'\n  autocorrect='off'\n  autocapitalize='off'\n  spellcheck='false'\n  rows='4'\n  cols='10'\n  class='textarea-input'\n  aria-label='finding title'\n  data-test-securityAnalysisDetails-findingTitle\n/>","moduleName":"irene/components/security/analysis-details/findings/table/title/index.hbs","parseOptions":{"srcName":"irene/components/security/analysis-details/findings/table/title/index.hbs"}});
import Component from '@glimmer/component';
import type { SecurityAnalysisFinding } from 'irene/models/security/analysis';

export interface SecurityAnalysisDetailsFindingsTableTitleComponentSignature {
  Args: {
    finding: SecurityAnalysisFinding;
  };
}

export default class SecurityAnalysisDetailsFindingsTableTitleComponent extends Component<SecurityAnalysisDetailsFindingsTableTitleComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'security/analysis-details/findings/table/title': typeof SecurityAnalysisDetailsFindingsTableTitleComponent;
  }
}
