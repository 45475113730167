import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkIconButton\n  data-test-manualScanLoginDetails-userRole-deleteBtn\n  @size='small'\n  {{on 'click' @onDeleteClick}}\n>\n  <AkIcon @iconName='delete' />\n</AkIconButton>", {"contents":"<AkIconButton\n  data-test-manualScanLoginDetails-userRole-deleteBtn\n  @size='small'\n  {{on 'click' @onDeleteClick}}\n>\n  <AkIcon @iconName='delete' />\n</AkIconButton>","moduleName":"irene/components/file-details/scan-actions/manual-scan/login-details/user-role-action/index.hbs","parseOptions":{"srcName":"irene/components/file-details/scan-actions/manual-scan/login-details/user-role-action/index.hbs"}});
import Component from '@glimmer/component';

export interface FileDetailsScanActionsManualScanLoginDetailsUserRoleActionSignature {
  Args: {
    onDeleteClick: () => void;
  };
}

export default class FileDetailsScanActionsManualScanLoginDetailsUserRoleActionComponent extends Component<FileDetailsScanActionsManualScanLoginDetailsUserRoleActionSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'file-details/scan-actions/manual-scan/login-details/user-role-action': typeof FileDetailsScanActionsManualScanLoginDetailsUserRoleActionComponent;
  }
}
